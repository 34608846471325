





































































































































































































































































.tit_dialogue_strong {
  cursor: pointer;
  text-decoration: underline;
  width: 120px;
  display: inline-block;
  margin-top: 6px;
}

.tertiary {
  border-radius: 2px;
  background-color: #5551ce;
  color: #fff;
}
